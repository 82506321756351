import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  type OnInit,
  ViewChild
} from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import {
  RegisterService,
  type TelephoneVerificationRequest,
  type User,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import {
  type CountryCallingCode,
  type CountryCode,
  getCountryCallingCode,
  parsePhoneNumber
} from 'libphonenumber-js/max'
import { NGXLogger } from 'ngx-logger'
import { delay } from 'rxjs/operators'
import { SharedService } from '../../../services/shared.service'
import { DataService } from '../../../services/data.service'
import { InstantErrorStateMatcherService } from '../../../services/instant-error-state-matcher.service'
import { type PhoneUsage } from '../../revolution/general-user-data/general-user-data.component'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { TransifexService } from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
@Component({
  selector: 'customer-portal-app-telephone-change-popup',
  templateUrl: './telephone-change-popup.component.html'
})
export class TelephoneChangePopupComponent implements OnInit {
  matcher = new InstantErrorStateMatcherService()
  public step: 'change' | 'verify' | 'done' = 'change'
  public maxLength
  disable = false
  error = false
  public changeForm = new FormGroup({
    mobilePrefix: new FormControl<string | null>(null),
    mobile: new FormControl<string | null>(null, [
      control => {
        if (
          control == null ||
          this.changeForm == null ||
          control.value == null
        ) {
          return {}
        }

        if (this.changeForm.value.mobilePrefix == null) {
          return { invalidNumber: true }
        }

        if (control.value.length < 2) {
          return { invalidNumber: true }
        }

        if (isNaN(Number(control.value))) {
          return { invalidNumber: true }
        }

        const num: string =
          String(this.changeForm.value.mobilePrefix) + String(control.value)

        const prefix: string = this.changeForm.value.mobilePrefix
        const prefixEntry = Object.entries(this.mobilePrefixes).find(entry => {
          return entry[1] === prefix.substr(1)
        })
        const prefixCountry = prefixEntry[0] as CountryCode

        const parsed = parsePhoneNumber(num, prefixCountry)

        if (!parsed.isPossible()) {
          return { invalidNumber: true }
        }

        if (!parsed.isValid()) {
          return { invalidNumber: true }
        }

        const numberType = parsed.getType()
        if (numberType !== 'MOBILE') {
          return { notMobile: true }
        }

        return {}
      }
    ])
  })

  private readonly mobilePrefixCountries: string[]
  public mobilePrefixes: Record<string, CountryCallingCode> = {}

  public verifyForm = new FormGroup({
    code0: new FormControl<string | null>(null),
    code1: new FormControl<string | null>(null),
    code2: new FormControl<string | null>(null),
    code3: new FormControl<string | null>(null),
    code4: new FormControl<string | null>(null),
    code5: new FormControl<string | null>(null)
  })

  @ViewChild('code0', { static: true }) private readonly code0: ElementRef
  @ViewChild('code1', { static: true }) private readonly code1: ElementRef
  @ViewChild('code2', { static: true }) private readonly code2: ElementRef
  @ViewChild('code3', { static: true }) private readonly code3: ElementRef
  @ViewChild('code4', { static: true }) private readonly code4: ElementRef
  @ViewChild('code5', { static: true }) private readonly code5: ElementRef
  private codes: ElementRef[]

  public code: string
  public resendDisabled = false

  private loading: boolean
  private readonly tel: string
  disabled = false
  sending = false
  verifying = false
  sameNumber = false
  public telephone = null
  public mobileOld: string
  public mobileBackup: string

  public phoneMask: string
  @ViewChild('mobileInput') mobileInput: ElementRef

  user: User
  usage: PhoneUsage
  constructor (
    private readonly dialogRef: MatDialogRef<TelephoneChangePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { usage: PhoneUsage, user: User },
    private readonly userService: UserService,
    private readonly registerService: RegisterService,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    public sharedService: SharedService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly transifexTranslationsService: TranslationService,
    public transifexService: TransifexService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    dialogRef.disableClose = true
    this.user = data.user
    this.usage = data.usage
    this.maxLength = this.remoteConfigService.get(
      'regEx.telephoneRegEx.maxLength'
    )
    this.mobilePrefixCountries =
    this.remoteConfigService.get('b2c.mobileCountryPrefixes')

    // to test screen
    // this.step = 'verify'
    // this.verifying = true
  }

  ngOnInit (): void {
    this.codes = [
      this.code0,
      this.code1,
      this.code2,
      this.code3,
      this.code4,
      this.code5
    ]
    this.load()

    // fill calling codes
    this.mobilePrefixCountries.forEach(countryCode => {
      this.mobilePrefixes[countryCode] = getCountryCallingCode(
        countryCode as CountryCode
      )
    })

    if (this.mobilePrefixCountries.length === 1) {
      this.changeForm.controls.mobilePrefix.setValue(
        '+' + this.mobilePrefixes[this.mobilePrefixCountries[0]].valueOf()
      )
    }

    // validate again after changing prefix
    this.changeForm.controls.mobilePrefix.valueChanges
      .pipe(delay(1))
      .subscribe(prefix => {
        this.phoneMask = this.sharedService.setPhoneMask(prefix, this.telephone)
        setTimeout(() => {
          this.changeForm.controls.mobile.updateValueAndValidity()
        })
      })

    // If 0 is in the first letter, remove it on post request
    this.changeForm.valueChanges.subscribe(value => {
      // check if the mask changed
      const currentMask = this.phoneMask
      const newMask = this.sharedService.setPhoneMask(
        value.mobilePrefix,
        value.mobile
      )
      if (currentMask !== newMask) {
        // set mask to undefined first so i dont get validator errors
        this.phoneMask = undefined
        this.cdRef.detectChanges()
        this.phoneMask = newMask
        this.cdRef.detectChanges()
        // return focus to input
        this.sharedService.setFocus(this.mobileInput)
      }

      this.telephone =
        String(value.mobilePrefix) +
        String(
          value.mobile?.[0] === '0' ? value.mobile.substr(1) : value.mobile
        )

      if (this.telephone != null && this.telephone === this.mobileBackup) {
        this.changeForm.controls.mobile.setErrors({
          currentNumber: 'currentNumber'
        })
      }
    })

    // submit when entered code
    this.verifyForm.valueChanges.subscribe(value => {
      this.code =
        String(value.code0) +
        String(value.code1) +
        String(value.code2) +
        String(value.code3) +
        String(value.code4) +
        String(value.code5)

      // submit only if all filled
      if (this.code.length === 6) {
        this.verify()
      }
    })

    // set form values
    this.setFormData()
  }

  load (): void {
    this.loading = true
    this.logger.debug(this.user)
    this.loading = false
  }

  isNumber (evt: KeyboardEvent): boolean {
    return this.sharedService.isNumber(evt)
  }

  setFormData (): void {
    const phones = this.sharedService.findUserPhone(this.user)
    const phone = this.usage === 'private' ? phones?.mobile : phones?.mobileBusiness
    if (phone != null) {
      try {
        const parsed = parsePhoneNumber(phone.number, null)
        this.changeForm
          .get('mobilePrefix')
          .setValue('+' + parsed.countryCallingCode)
        this.changeForm.get('mobile').setValue(parsed.nationalNumber)
        this.mobileBackup = phone.number
      } catch (error) {
        this.logger.error(error)
      }
    } else {
      // set default
      const country: string = this.remoteConfigService.get('country.code')
      this.changeForm
        .get('mobilePrefix')
        .setValue('+' + this.mobilePrefixes[country.toUpperCase()])
    }
  }

  sendVerification (): void {
    this.mobileOld = this.telephone
    this.sending = true
    this.error = false
    const telephone: TelephoneVerificationRequest = {
      number: this.telephone,
      type: 'mobile',
      usage: this.usage
    }
    const lang =
      this.user?.language != null
        ? this.user?.language.find(s => s.primary)?.language
        : 'DE'
    this.userService.sendTelephoneVerification(telephone, null, lang).subscribe(
      () => {
        this.step = 'verify'
      },
      err => {
        if (err.error.code === 1005) {
          this.changeForm.controls.mobile.setErrors({
            currentNumber: 'currentNumber'
          })
        } else if (err.error.code === 1003) {
          this.changeForm.controls.mobile.setErrors({
            dailyLimit: 'dailyLimit'
          })
        } else if (err.error.code === 1004) {
          this.changeForm.controls.mobile.setErrors({
            maxTries: 'maxTries'
          })
        } else {
          this.step = 'done'
        }
        this.error = true
        this.sending = false
      },
      () => {
        this.sending = false
      }
    )
  }

  focusFirstInput (): void {
    if (this.code0?.nativeElement) {
      this.code0.nativeElement.focus()
      this.code0.nativeElement.select()
    }
  }

  verify (): void {
    this.verifying = true
    const lang =
      this.user?.language != null
        ? this.user?.language.find(s => s.primary)?.language
        : 'DE'
    this.userService.verifyTelephone(this.code, null, lang).subscribe(
      () => {
        this.step = 'done'
        this.dataService.phoneNumberVerified(this.telephone)
      },
      err => {
        this.verifyForm.reset()
        this.verifying = false
        this.focusFirstInput()
        if (err.error.code === 1006) {
          this.verifyForm.setErrors({
            wrongCode: 'wrongCode'
          })
        }
        if (err.error.code === 1003) {
          this.verifyForm.setErrors({
            dailyLimit: 'dailyLimit'
          })
        }
        if (err.error.code === 1004) {
          this.verifyForm.disable()
          this.verifyForm.setErrors({
            maxTries: 'maxTries'
          })
        }
        if (err.error.code === 1001) {
          this.verifyForm.setErrors({
            expired: 'expired'
          })
        }
      },
      () => {
        this.verifying = false
      }
    )
  }

  public keyup ($event: KeyboardEvent, index: number): void {
    // focus last if not first
    if (
      index > 0 &&
      ($event.key === 'Backspace' || $event.key === 'ArrowLeft')
    ) {
      const element = this.codes[index - 1].nativeElement as HTMLInputElement
      element.focus()
      element.setSelectionRange(0, 1)
    }

    // focus next if not last
    if (
      index < this.codes.length - 1 &&
      Number.isInteger(parseInt($event.key, 10))
    ) {
      const element = this.codes[index + 1].nativeElement as HTMLInputElement
      element.focus()
      element.setSelectionRange(0, 1)
    }
  }

  public focus (index: number): void {
    const element = this.codes[index].nativeElement as HTMLInputElement
    if (element.value == null) {
      element.value = '0'
    }

    // on focus select all
    if (element != null) {
      element.setSelectionRange(0, 1)
    }
  }

  close (): void {
    this.dialogRef.close(true)
  }

  getErrorTranslateKey (errors, translateKeyPrefix): string {
    return this.sharedService.getErrorTranslateKey(errors, translateKeyPrefix)
  }

  getTranslation (): string {
    return this.transifexTranslationsService.translate(
      'Enter the 6-digit code we just sent you',
      {
        _key: 'customerPortal.customer-portal.telephone-validation.text',
        _tags: 'customer-portal, 3.1',
        phoneNumber: this.mobileOld
      }
    )
  }
}
